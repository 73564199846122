// Webpack imports
// Global var to use it across components
import '../scss/styles.scss';

// Class and Function Imports
// import Demo from './template.demo';
import GoogleTrackingEvents from './../../../Digon.WebPack/src/js/googleTrackingEvents';

document.querySelector('html').classList.remove('no-js');

var inFrame = function () {
    return !(window === window.parent);
};

if (!inFrame()) {
    new GoogleTrackingEvents().init();
}

// import '../static/legacy';
