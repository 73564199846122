/*
 *  Class Google Tracking
 */
export
default class GoogleTrackingEvents {
    constructor() {
        window.dataLayer = window.dataLayer || [];
        this.gtag('js', new Date());
    }

    gtag(args){
        dataLayer.push(args);
    }

    createEventObject(eventName, element) {

        if ( !eventName || !element || !(element instanceof HTMLElement) ) {
            return {};
        }

        switch(eventName) {
            case 'click':
                eventName = 'select_promotion';
                break;
            case 'view':
                eventName = 'view_promotion';
                break;
        }

        const returnObj = {
            'event': eventName,
            'ecommerce': {
                'items': [
                    {
                        'promotion_id': element.getAttribute('tracking-promotion-id') || '',
                        'creative_slot': element.getAttribute('tracking-creative-slot') || '',
                        'location_id': element.getAttribute('tracking-location-id') || '',
                        'item_id': element.getAttribute('tracking-item-id') || '',
                        'offer_type': element.getAttribute('tracking-offer-type') || '',
                    }
                ]
            }
        };

        // @todo: remove if everything okay
        console.log(eventName, returnObj, 'trigger');

        return returnObj;
    };

    componentsTracking() {

        const elements = document.querySelectorAll('[tracking-events]');
        const _this = this;

        elements.forEach(function (element) {
            element.addEventListener('click', function(event) {
                const closest = event.target.closest('[tracking-events]');
                const trackingObject = _this.createEventObject('click', closest);

                _this.gtag(trackingObject);
            });
        });
    }

    observerTracking() {

        if ('IntersectionObserver' in window) {
            const observer = new IntersectionObserver((entries) => {
                this.intersectionCallback(entries, this);
            });
            const observedItems = document.querySelectorAll('[tracking-events]');

            observedItems.forEach( ( observedItem ) => {
                observer.observe(observedItem);
            });
        }

    }

    intersectionCallback(entries, currentThis) {
        const _this = currentThis;
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if ( !entry.target.hasAttribute('event-tracked') ) {
                    const closest = entry.target.closest('[tracking-events]');
                    const trackingObject = _this.createEventObject('view', closest);

                    _this.gtag(trackingObject);

                    entry.target.setAttribute('event-tracked', true);
                }
            }
        });
    };

    init() {

        this.observerTracking();
        this.componentsTracking();

    }
}
